
import { Component, Prop, Vue } from "vue-property-decorator";
import { SidebarItem } from "../../../interfaces/sidebarProps";
import { LanguageSwitcherProps } from "../../../interfaces/language-switcherProps";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher.vue";
import { subIsActive } from "../../../utils/helpers";

interface Attr {
  "input-value": boolean | null | undefined;
  to: string | null | undefined;
  href: string | null | undefined;
}

interface Listener {
  click: () => void | undefined;
}

@Component({ components: { LanguageSwitcher } })
export default class DefaultLayoutSidebarItem extends Vue {
  @Prop({ required: true }) readonly item?: SidebarItem;
  @Prop() languageSwitcherProps?: LanguageSwitcherProps;

  itemIsActive(path: string | null): boolean {
    if (path) {
      return subIsActive(path, this.$route);
    }
    return false;
  }

  get attrs(): Attr {
    return {
      "input-value": this.item?.isActive,
      to: this.item?.to,
      href: this.item?.href,
    };
  }

  get listeners(): Listener {
    return {
      click: this.item?.onClick || (() => undefined),
    };
  }
}
