interface AccountLoginUrls {
  loginUrl: string;
  siteUrl: string;
}

export const accountsLoginUrl = (
  locale: string,
  accountLoginUrls: AccountLoginUrls
): string => {
  return (
    accountLoginUrls.loginUrl +
    "?lang=" +
    locale +
    "&redirecturi=" +
    accountLoginUrls.siteUrl
  );
};

export const redirect = (link: string): void => {
  window.location.href = link;
};

export const changeLanguage = (
  locale: string,
  i18n: any,
  router: any,
  route: any
): void => {
  if (i18n.locale !== locale) {
    i18n.locale = locale;
    localStorage.setItem("locale", locale);
    router.push({
      to: router.currentRoute.path,
      query: {
        ...route.query,
        lang: locale,
      },
    });
  }
};

export class IntervalTimer {
  callbackStartTime = 0;
  remaining = 0;
  paused = false;
  timerId: ReturnType<typeof setInterval> | null = null;
  _callback;
  _delay: number;

  constructor(callback: () => void, delay: number) {
    this._callback = callback;
    this._delay = delay;
  }

  pause() {
    if (!this.paused) {
      this.clear();
      this.remaining = new Date().getTime() - this.callbackStartTime;
      this.paused = true;
    }
  }

  resume() {
    if (this.paused) {
      if (this.remaining) {
        setTimeout(() => {
          this.run();
          this.paused = false;
          this.start();
        }, this.remaining);
      } else {
        this.paused = false;
        this.start();
      }
    }
  }

  clear() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  start() {
    this.clear();
    this.timerId = setInterval(() => {
      this.run();
    }, this._delay);
  }

  run() {
    this.callbackStartTime = new Date().getTime();
    this._callback();
  }
}
