
import { Component, Prop, Vue } from "vue-property-decorator";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import OsdiTextField from "@/../shared-ui/components/Osdi/OsdiTextField.vue";
import OsdiDropDown from "@/components/OsdiDropDown.vue";
import { MachineData, UserData, ToolingData, ConsultingData } from "@/models";
import ConsultingStore from "@/store/consultingModule";
import { random } from "lodash";
import { Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { ConsultingDataAPI } from "../api/consultingData";
import awsconfig from "@/aws-config";
import SideBar from "../components/SideBar.vue";

@Component<Machine>({
  components: {
    SideBar,
    OsdiButton,
    OsdiTextField,
    OsdiDropDown,
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = `Fette Compacting | ${this.pageTitle}`;
      },
    },
  },
})
export default class Machine extends Vue {
  @Prop() private msg!: string;
  pageTitle: string = this.$i18n.t("page.machine.browsertab.title").toString();

  private machineData: MachineData = JSON.parse(
    JSON.stringify(ConsultingStore.consultingData.machineData)
  );

  private userData: UserData = ConsultingStore.userData;
  private toolingData: ToolingData = ConsultingStore.consultingData.toolingData;
  private client = new ConsultingDataAPI(awsconfig.API);

  private labelMachineType = false;
  private labelWindowsVersion = false;
  private labelMACOVersion = false;
  private machineType = [
    "P1010",
    "102i",
    "1200i",
    "F10i",
    "F10i CG3",
    "1090 WiP",
    "P2020",
    "2200i",
    "2090i",
    "F20i",
    "F20i CG3",
    "2090 WiP",
    "P3030",
    "3200i",
    "3090i",
    "F30i",
    "F30i CG3",
    "3090 WiP",
    "4090i",
    "FE35",
    "FE55",
    "FE55 CG3",
    "FE75",
    "FE75 CG3",
  ];

  mounted() {
    this.machineData = JSON.parse(
      JSON.stringify(ConsultingStore.consultingData.machineData)
    );
  }

  updated() {
    ConsultingStore.SET_MACHINEDATA(this.machineData);
  }
  async upload() {
    if (!this.userData.userId) {
      this.userData.userId = uuidv4();
    }
    console.log(this.userData.userId);
    ConsultingStore.consultingData.userId = this.userData.userId;
    await Storage.put(
      this.userData.userId + "/consultingData/machineData.json",
      this.machineData
    );
    const result = await Storage.list("");
    console.log(result);
    const saveResult: boolean = await this.client.saveConsutlingData(
      ConsultingStore.consultingData
    );
    console.log(
      `Saving the consulting data to the database returned with the following response: ${saveResult}`
    );
  }

  // // save consulting data to database
  // async save() {
  //   console.log(ConsultingStore.consultingData);
  //   if (!this.userData.userId) {
  //     this.userData.userId = uuidv4();
  //   }
  //   console.log(this.userData.userId);
  //   ConsultingStore.consultingData.userId = this.userData.userId;
  //   await this.client.saveConsutlingData(ConsultingStore.consultingData);
  //   const result = await Storage.list("");
  //   console.log(result);
  // }

  // // Get consulting data from database
  // async get() {
  //   if (!this.userData.userId) {
  //     console.log("no user id found");
  //   }
  //   console.log(this.userData.userId);
  //   const result: ConsultingData = (await this.client.getConsutlingData(
  //     this.userData.userId
  //   )) as ConsultingData;
  //   console.log(result);
  // }

  setEnable() {
    return (
      this.machineData.conYear.length > 0 &&
      this.machineData.serialNumber.length > 0 &&
      this.machineData.opcua.length > 0 &&
      this.machineData.machineType.length > 0 &&
      this.machineData.visualVersion.length > 0 &&
      this.machineData.windowsVersion.length > 0 &&
      this.machineData.macoVersion.length > 0
    );
  }
}
