
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  SidebarItem,
  SidebarLogoProps,
} from "../../../interfaces/sidebarProps";
import { LanguageSwitcherProps } from "../../../interfaces/language-switcherProps";
import DefaultLayoutSidebarItems from "./DefaultLayoutSidebarItems.vue";
import DefaultLayoutSidebarLogo from "./DefaultLayoutSidebarLogo.vue";

@Component({
  name: "DefaultLayoutSidebar",
  components: {
    DefaultLayoutSidebarLogo,
    DefaultLayoutSidebarItems,
  },
})
export default class DefaultLayoutSidebar extends Vue {
  @Prop() readonly topItems?: SidebarItem[];
  @Prop() readonly bottomItems?: SidebarItem[];
  @Prop() readonly typeApp?: SidebarItem[];
  @Prop() languageSwitcherProps?: LanguageSwitcherProps;
  @Prop() readonly sidebarLogoProps?: SidebarLogoProps;

  protected breakPointStatus = this.$vuetify.breakpoint.lgAndDown;

  get isBreakpointEnabled(): boolean {
    return this.breakPointStatus;
  }

  variantUpdated(status: boolean): void {
    this.breakPointStatus = status;
  }

  get languageSwitcher(): LanguageSwitcherProps {
    return {
      availableLanguages: this.languageSwitcherProps?.availableLanguages,
      onClickHandler: this.languageSwitcherProps?.onClickHandler,
    };
  }

  get sidebarLogo(): SidebarLogoProps {
    return {
      imgVariant: this.sidebarLogoProps?.imgVariant,
      textVariant: this.sidebarLogoProps?.textVariant,
    };
  }
}
