
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import ConsultingCardInterface from "@/../shared-ui/interfaces/consulting-card";

@Component({
  name: "RequestConsulting",
  components: { OsdiButton },
})
export default class RequestConsulting extends Vue {
  @Prop() readonly consultingCard!: ConsultingCardInterface;
}
