
import { Component, Prop, Vue } from "vue-property-decorator";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import OsdiTextField from "@/../shared-ui/components/Osdi/OsdiTextField.vue";
import OsdiDropDown from "@/components/OsdiDropDown.vue";
import { SetupData, UserData } from "@/models";
import ConsultingStore from "@/store/consultingModule";
import { Storage } from "aws-amplify";
import { ConsultingDataAPI } from "../api/consultingData";
import awsconfig from "@/aws-config";

@Component({
  components: {
    OsdiButton,
    OsdiTextField,
    OsdiDropDown,
  },
})
export default class Setup extends Vue {
  @Prop() private msg!: string;
  private userData: UserData = ConsultingStore.userData;
  private setupData: SetupData = JSON.parse(
    JSON.stringify(ConsultingStore.consultingData.setUpData)
  );

  mounted(): void {
    this.setupData = JSON.parse(
      JSON.stringify(ConsultingStore.consultingData.setUpData)
    );
  }
  updated(): void {
    ConsultingStore.SET_SETUPDATA(this.setupData);
  }
  setEnable(): boolean {
    return (
      this.setupData.layout.length > 0 &&
      this.setupData.sorting1.length > 0 &&
      this.setupData.sorting2.length > 0 &&
      this.setupData.materialPlough.length > 0 &&
      this.setupData.materialScraper.length > 0 &&
      this.setupData.tabletTester.length > 0 &&
      this.setupData.metalDetector.length > 0 &&
      this.setupData.dustExtraction.length > 0 &&
      this.setupData.productLoading.length > 0
    );
  }

  private client = new ConsultingDataAPI(awsconfig.API);
  async upload(): Promise<void> {
    await Storage.put(
      this.userData.userId + "/consultingData/SetUpData.json",
      this.setupData
    );
    const result = await Storage.list("");
    await this.client.saveConsutlingData(ConsultingStore.consultingData);
    console.log(result);
  }
}
