const awsconfig = {
  API: {
    region: process.env.VUE_APP_AWS_REGION,
    aws_appsync_authenticationType: process.env.VUE_APP_AWS_APPSYNC_AUTH_TYPE,
    aws_appsync_region: process.env.VUE_APP_AWS_REGION,
    aws_appsync_graphqlEndpoint:
      process.env.VUE_APP_REMOTE_CONSULTING_GRAPHQL_API_URL,
  },
};

export default awsconfig;
