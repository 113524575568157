
import { Component, Prop, Vue } from "vue-property-decorator";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import OsdiTextField from "@/../shared-ui/components/Osdi/OsdiTextField.vue";
import OsdiDropDown from "@/components/OsdiDropDown.vue";
import OsdiFileInput from "@/components/OsdiFileInput.vue";
import { ProductData, FileData, UserData } from "@/models";
import ConsultingStore from "@/store/consultingModule";
import { Storage } from "aws-amplify";
import { ConsultingDataAPI } from "../api/consultingData";
import awsconfig from "@/aws-config";

@Component({
  components: {
    OsdiButton,
    OsdiTextField,
    OsdiDropDown,
    OsdiFileInput,
  },
})
export default class Product extends Vue {
  @Prop() private msg!: string;
  private label = false;
  private fileData: FileData = ConsultingStore.fileData;
  private materialDataSheet = {};
  private userData: UserData = ConsultingStore.userData;
  private productData: ProductData = JSON.parse(
    JSON.stringify(ConsultingStore.consultingData.productData)
  );

  mounted(): void {
    this.productData = JSON.parse(
      JSON.stringify(ConsultingStore.consultingData.productData)
    );
  }
  updated(): void {
    ConsultingStore.SET_PRODUCTDATA(this.productData);
  }

  setEnable(): boolean {
    return (
      this.productData.activeIngredient.length > 0 &&
      this.productData.productName.length > 0 &&
      this.productData.apiContent.length > 0 &&
      this.productData.meltingPoint.length > 0 &&
      this.productData.material.length > 0 &&
      this.productData.practicalSizeDistribution.length > 0 &&
      this.productData.residualMoisture.length > 0 &&
      this.productData.bulkDensity.length > 0 &&
      this.productData.tappedDensity.length > 0
    );
  }
  attachMaterialSaftyDataSheetF(e: Event): void {
    this.materialDataSheet = e;
    this.fileData.files.push(this.materialDataSheet);
    console.log(this.materialDataSheet);
  }

  private client = new ConsultingDataAPI(awsconfig.API);
  async upload(): Promise<void> {
    await Storage.put(
      this.userData.userId + "/consultingData/productData.json",
      this.productData
    );
    const result = await Storage.list("");
    await this.client.saveConsutlingData(ConsultingStore.consultingData);
    console.log(result);
  }
}
