<template>
  <v-file-input
    class="file-input"
    v-bind="$attrs"
    v-on="$listeners"
  ></v-file-input>
</template>

<style scoped>
.file-input {
  margin-left: 80px;
  width: 140px;
  height: 42px;
  padding: 0;
  margin-top: 24px;
  margin-right: 3px;
  border: solid 1px #008b95;
}
.file-input ::v-deep .v-input__slot::before {
  border-color: #008b95 !important;
}

.file-input
  ::v-deep
  .theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: #008b95;
}
.file-input ::v-deep .mdi-paperclip::before {
  color: #008b95;
}
.file-input ::v-deep .v-text-field__slot {
  height: 32px !important;
}
.file-input ::v-deep .v-input__control {
  margin-right: 5px;
}

.file-input ::v-deep .v-file-input__text.v-file-input__text--placeholder {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px !important;

  color: #008b95 !important;
}
</style>

<script>
export default {};
</script>
