// import {
//   ConsultingData,
//   MachineData,
//   SetupData,
//   FillingData,
//   ToolingData,
//   ParametersData,
//   ProductData,
//   TabletData,
//   AnalysisData,
// } from "@/models";
// import {
//   Module,
//   VuexModule,
//   Mutation,
//   Action,
//   getModule,
// } from "vuex-module-decorators";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
export default new Vuex.Store({});
