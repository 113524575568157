export const getConsultingData = `query GetConsultingData($userId: String) {
    getConsultingData(userId: $userId) {
        userId
        machineData{
            conYear
            serialNumber
            opcua
            machineType
            visualVersion
            windowsVersion
            macoVersion
        }
        setUpData{
            layout
            sorting1
            sorting2
            materialPlough
            materialScraper
            tabletTester
            metalDetector
            dustExtraction
            productLoading
        }
        fillingData{
            fillingDeviceModel
            fillingTypeModel
            discribeFillingDevice
            fillingType
            dosingWheel
            fillingWheel
            distributionWheel
            direction
            intermediatePlate
            basePlate
            sealing
            distance
            fillingConeSealing
            material
        }
        toolingData{
            numberOfPunches
            punchType
            punchCoating
            tabletOrientation
            dies
            diesTaper
            segmentsID
            segmentsTaper
            toolingDistributorSegment
            toolingDistributorDie
        }
        parametersData{
            tabletsPerHours
            fillingDeviceSpeed
            mainCompressionForces
            fillingDepth
            preCompressionForces
            mainCompressionStationCylindricalHeight
            preCompressionStationCylindricalHeight
            mainCompressionStationPenetrationDepth
            mainCompressionStationPenetrationDepth2
            fillCam
            mainCompressionSRel
            parameterBatchDD1
            parameterBatchDD2
            parameterBatchDD3
            parameterBatchDD4
            parameterBatchDD5
        }
        productData{
            activeIngredient
            productName
            apiContent
            meltingPoint
            material
            practicalSizeDistribution
            residualMoisture
            bulkDensity
            tappedDensity
            specialties
        }
        tabletData{
            height
            weight
            hardness
            form
            oblongNarrowSide
            oblongLongSide
            diameter
            curvatureRadius
        }
        analysisData{
            topic
            majorIssues
            typicallyOccur
            impactProduction
            typicallyOccurOthers
            consultingMode
            relatePerformance
            name
            surname
            company
            emailAddress
            phoneNumber
        }
    }
}`;

export const sendEmailOnSubmit = `query SendEmailOnSubmit($userId: String) {
    sendEmailOnSubmit(userId: $userId)
}`;
