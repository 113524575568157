
import { Vue, Component } from "vue-property-decorator";
import TopBar from "../src/components/TopBar.vue";
import SideBar from "../src/components/SideBar.vue";

@Component({
  components: {
    SideBar,
    TopBar,
  },
})
export default class App extends Vue {}
