import { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { ConsultingData } from "../../../frontend/src/models";
import * as mutations from "./graphql/mutations";
import * as queries from "./graphql/queries";
import { GraphQlApi, GraphQlApiOptions } from "./graphqlApi";

export class ConsultingDataAPI extends GraphQlApi {
  constructor(params: GraphQlApiOptions) {
    super(params);
  }

  async getConsutlingData(userId: string): Promise<ConsultingData | undefined> {
    const { data } = await (this.client.graphql(
      graphqlOperation(queries.getConsultingData, { userId })
    ) as Promise<GraphQLResult<{ getConsultingData: ConsultingData }>>);
    return data?.getConsultingData;
  }

  async sendEmailOnSubmit(userId: string): Promise<boolean> {
    const { data } = await (this.client.graphql(
      graphqlOperation(queries.sendEmailOnSubmit, { userId })
    ) as Promise<GraphQLResult<{ sendEmailOnSubmitResult: boolean }>>);
    return data?.sendEmailOnSubmitResult ?? false;
  }

  async saveConsutlingData(consultingData: ConsultingData): Promise<boolean> {
    // do we need an intermediate object? Multi threading issue?
    const { data } = await (this.client.graphql(
      graphqlOperation(mutations.saveConsultingData, {
        consultingData: consultingData,
      })
    ) as Promise<GraphQLResult<{ saveConsultingData: boolean }>>);
    return data?.saveConsultingData ?? false;
  }
}
