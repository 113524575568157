import VueI18n from "vue-i18n";
import Vue from "vue";
import en from "./locales/en.json";
import zh from "./locales/zh.json";
import de from "./locales/de.json";
import { defaultLanguage } from "@/i18n/languages";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: defaultLanguage,
  messages: {
    en: {
      ...en,
    },
    de: {
      ...de,
    },
    zh: {
      ...zh,
    },
  },
  fallbackLocale: defaultLanguage,
});

export default i18n;
