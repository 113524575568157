import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import { API, Auth, Storage } from "aws-amplify";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

// Change the region key for config object from region to aws_project_region based on the AWS Amplify source code
// from: https://github.com/aws-amplify/amplify-js/blob/a047ce73/packages/api/src/API.ts#L64
API.configure({
  aws_project_region: process.env.VUE_APP_AWS_REGION,
  aws_appsync_authenticationType: process.env.VUE_APP_AWS_APPSYNC_AUTH_TYPE,
  aws_appsync_region: process.env.VUE_APP_AWS_REGION,
  aws_appsync_graphqlEndpoint:
    "https://2ngrbg2s75eedkya5c7r4zljiq.appsync-api.eu-central-1.amazonaws.com/graphql",
});

Auth.configure({
  identityPoolId: process.env.VUE_APP_IDENTITY_POOL,
  region: process.env.VUE_APP_AWS_REGION,
  userPoolId: process.env.VUE_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
});

Storage.configure({
  bucket: process.env.VUE_APP_STORAGE_BUCKET_NAME,
  region: process.env.VUE_APP_AWS_REGION,
  customPrefix: { public: "consultingRequests" },
});
