
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class LanguageSwitcher extends Vue {
  @Prop() readonly availableLanguages?: string[];
  @Prop() readonly languageHandler?: (language: string) => void;
  @Prop({ default: false }) readonly sidebarIsActive?: boolean;

  private currentLanguage = this.$i18n.locale;
  private languages: string[] = [];

  protected selectedIndex = this.languages.findIndex(
    (l) => l === this.currentLanguage
  );
  protected panels: Array<boolean> = [];

  created(): void {
    this.languages = this.availableLanguages ?? [];
    this.resetPanels();
  }

  @Watch("sidebarIsActive")
  sidebarIsActiveChanged(status: boolean): void {
    if (!status) {
      this.resetPanels();
    }
  }

  resetPanels(): void {
    this.panels = new Array(this.languages).map(() => false);
  }

  protected changeLanguage(language: string): void {
    this.currentLanguage = language;
    if (this.languageHandler) {
      this.languageHandler(language);
    }
  }
}
