
import { Vue, Component } from "vue-property-decorator";
import Layout from "../components/Layout.vue";
import RequestConsulting from "../components/RequestConsulting.vue";
import ConsultingCardInterface from "@/../shared-ui/interfaces/consulting-card";

@Component<Index>({
  name: "Index",
  components: { Layout, RequestConsulting },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = this.pageTitle();
      },
    },
  },
})
export default class Index extends Vue {
  private pageTitle(): string {
    return this.$i18n.t("page.index.browsertab.title").toString();
  }
  private cards(): ConsultingCardInterface[] {
    return [
      {
        id: 1,
        imageUrl: "img/general/RequestConsulting.svg",
        title: "Application Consulting",
        description:
          "Gain tailored insights on how to optimize your process, increase your output or decrease\
          your yield via a remote video call or during an onsite consultancy at your company.",
        buttonText: "Request consultancy",
      },
    ];
  }
}
