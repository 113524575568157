
import { Component, Prop, Vue } from "vue-property-decorator";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import OsdiTextField from "@/../shared-ui/components/Osdi/OsdiTextField.vue";
import OsdiDropDown from "@/components/OsdiDropDown.vue";
import { ToolingData, MachineData, UserData } from "@/models";
import ConsultingStore from "@/store/consultingModule";
import { Storage } from "aws-amplify";
import { ConsultingDataAPI } from "../api/consultingData";
import awsconfig from "@/aws-config";

@Component({
  components: {
    OsdiButton,
    OsdiTextField,
    OsdiDropDown,
  },
})
export default class Tooling extends Vue {
  @Prop() private msg!: string;
  private machineData: MachineData = ConsultingStore.consultingData.machineData;
  private userData: UserData = ConsultingStore.userData;
  private displaySeg = "display: none";
  private displayDie = "display: none";
  private labelPunchType = false;
  private labelPunchCoating = false;
  private displayRadio = "display: none";
  private dieOrSeg = "";
  private punchCoatingType = ["None", "HCP", "PCR", "CRN", "TIN", "TAN"];
  private punchTypes = [
    "FS12",
    "EU19",
    "EU19FS",
    "FS19",
    "EU1“",
    "EU1“ 441",
    "TSM 19",
    "TSM 1“",
    "EU35",
  ];
  private toolingData: ToolingData = JSON.parse(
    JSON.stringify(ConsultingStore.consultingData.toolingData)
  );

  mounted(): void {
    this.toolingData = JSON.parse(
      JSON.stringify(ConsultingStore.consultingData.toolingData)
    );
    this.segemntOrDie();
  }
  updated(): void {
    ConsultingStore.SET_TOOLINGDATA(this.toolingData);
  }

  setEnable(): boolean {
    return (
      (this.toolingData.numberOfPunches.length > 0 &&
        this.toolingData.punchType.length > 0 &&
        this.toolingData.punchCoating.length > 0 &&
        this.toolingData.tabletOrientation.length > 0 &&
        this.toolingData.dies.length > 0 &&
        this.toolingData.diesTaper.length > 0 &&
        this.toolingData.toolingDistributorDie.length > 0) ||
      (this.toolingData.numberOfPunches.length > 0 &&
        this.toolingData.punchType.length > 0 &&
        this.toolingData.punchCoating.length > 0 &&
        this.toolingData.tabletOrientation.length > 0 &&
        this.toolingData.segmentsID.length > 0 &&
        this.toolingData.segmentsTaper.length > 0 &&
        this.toolingData.toolingDistributorSegment.length > 0)
    );
  }
  radioDieSeg(): void {
    if (this.dieOrSeg == "radio-die") {
      this.displayDie = "display: ";
      this.displaySeg = "display: none";
    } else if (this.dieOrSeg == "radio-segment") {
      this.displayDie = "display: none";
      this.displaySeg = "display: ";
    }
  }

  segemntOrDie(): void {
    if (this.machineData.machineType == "102i") {
      if (
        this.toolingData.punchType == "EU19" ||
        this.toolingData.punchType == "EU19FS" ||
        this.toolingData.punchType == "FS19" ||
        this.toolingData.punchType == "TSM 19"
      ) {
        if (
          this.toolingData.numberOfPunches == "6" ||
          this.toolingData.numberOfPunches == "16" ||
          this.toolingData.numberOfPunches == "32" ||
          this.toolingData.numberOfPunches == "30" ||
          this.toolingData.numberOfPunches == "24"
        ) {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "99") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (
        this.toolingData.punchType == "EU1“" ||
        this.toolingData.punchType == "TSM 1“"
      ) {
        if (
          this.toolingData.numberOfPunches == "6" ||
          this.toolingData.numberOfPunches == "16" ||
          this.toolingData.numberOfPunches == "20"
        ) {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "24") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (this.toolingData.punchType == "EU1“ 441") {
        if (this.toolingData.numberOfPunches == "20") {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "21") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      }
    } else if (this.machineData.machineType == "1200i") {
      if (
        this.toolingData.punchType == "EU19" ||
        this.toolingData.punchType == "EU19FS" ||
        this.toolingData.punchType == "FS19"
      ) {
        if (
          this.toolingData.numberOfPunches == "32" ||
          this.toolingData.numberOfPunches == "30" ||
          this.toolingData.numberOfPunches == "24"
        ) {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "99") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (this.toolingData.punchType == "TSM19") {
        if (this.toolingData.numberOfPunches == "30") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (this.toolingData.punchType == "EU1“") {
        if (this.toolingData.numberOfPunches == "20") {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "24") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (this.toolingData.punchType == "TSM 1“") {
        if (this.toolingData.numberOfPunches == "24") {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        }
      } else if (this.toolingData.punchType == "EU1“ 441") {
        if (this.toolingData.numberOfPunches == "20") {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "21") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (this.toolingData.punchType == "FS12") {
        if (this.toolingData.numberOfPunches == "45") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      }
    } else if (
      this.machineData.machineType == "2090i" ||
      this.machineData.machineType == "2090 WiP"
    ) {
      if (
        this.toolingData.punchType == "EU19" ||
        this.toolingData.punchType == "EU19FS" ||
        this.toolingData.punchType == "FS19"
      ) {
        if (
          this.toolingData.numberOfPunches == "47" ||
          this.toolingData.numberOfPunches == "43" ||
          this.toolingData.numberOfPunches == "36"
        ) {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "45") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (
        this.toolingData.punchType == "EU1“" ||
        this.toolingData.punchType == "TSM 1“"
      ) {
        if (this.toolingData.numberOfPunches == "30") {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "36") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (this.toolingData.punchType == "EU1“ 441") {
        if (this.toolingData.numberOfPunches == "30") {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "36") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (this.toolingData.punchType == "TSM 19") {
        if (
          this.toolingData.numberOfPunches == "36" ||
          this.toolingData.numberOfPunches == "43"
        ) {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "45") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (this.toolingData.punchType == "FS12") {
        if (this.toolingData.numberOfPunches == "66") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      }
    } else if (this.machineData.machineType == "2200i") {
      if (
        this.toolingData.punchType == "EU19" ||
        this.toolingData.punchType == "EU19FS" ||
        this.toolingData.punchType == "FS19"
      ) {
        if (
          this.toolingData.numberOfPunches == "47" ||
          this.toolingData.numberOfPunches == "43" ||
          this.toolingData.numberOfPunches == "36"
        ) {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "45") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (
        this.toolingData.punchType == "EU1“" ||
        this.toolingData.punchType == "TSM 1“"
      ) {
        if (this.toolingData.numberOfPunches == "30") {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "36") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (this.toolingData.punchType == "TSM 19") {
        if (
          this.toolingData.numberOfPunches == "36" ||
          this.toolingData.numberOfPunches == "43"
        ) {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "45") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (this.toolingData.punchType == "EU35") {
        this.displayDie = "display: ";
        this.displaySeg = "display: none";
      } else if (this.toolingData.punchType == "EU1“ 441") {
        if (this.toolingData.numberOfPunches == "30") {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "33") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (this.toolingData.punchType == "FS12") {
        if (this.toolingData.numberOfPunches == "66") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      }
    } else if (
      this.machineData.machineType == "3090i" ||
      this.machineData.machineType == "3090 WiP"
    ) {
      if (
        this.toolingData.punchType == "EU19" ||
        this.toolingData.punchType == "EU19FS" ||
        this.toolingData.punchType == "FS19"
      ) {
        if (
          this.toolingData.numberOfPunches == "79" ||
          this.toolingData.numberOfPunches == "73" ||
          this.toolingData.numberOfPunches == "61"
        ) {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "75") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (
        this.toolingData.punchType == "EU1“" ||
        this.toolingData.punchType == "TSM 1“" ||
        this.toolingData.punchType == "EU1“ 441"
      ) {
        if (this.toolingData.numberOfPunches == "49") {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "55") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (
        this.toolingData.punchType == "TSM 19" &&
        (this.toolingData.numberOfPunches == "73" ||
          this.toolingData.numberOfPunches == "61")
      ) {
        this.displayDie = "display: ";
        this.displaySeg = "display: none";
      } else if (this.toolingData.numberOfPunches == "75") {
        this.displayDie = "display: none";
        this.displaySeg = "display: ";
      } else if (this.toolingData.punchType == "FS12") {
        if (this.toolingData.numberOfPunches == "110") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      }
    } else if (this.machineData.machineType == "3200i") {
      if (
        this.toolingData.punchType == "EU19" ||
        this.toolingData.punchType == "FS19"
      ) {
        if (
          this.toolingData.numberOfPunches == "79" ||
          this.toolingData.numberOfPunches == "73" ||
          this.toolingData.numberOfPunches == "61"
        ) {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "75") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (
        this.toolingData.punchType == "EU1“" ||
        this.toolingData.punchType == "TSM 1“" ||
        this.toolingData.punchType == "EU1“ 441"
      ) {
        if (this.toolingData.numberOfPunches == "49") {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "55") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (this.toolingData.punchType == "TSM 19") {
        if (
          this.toolingData.numberOfPunches == "73" ||
          this.toolingData.numberOfPunches == "61"
        ) {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "75") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (this.toolingData.punchType == "EU35") {
        if (this.toolingData.numberOfPunches == "37") {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "45") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (this.toolingData.punchType == "FS12") {
        if (this.toolingData.numberOfPunches == "110") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      }
    } else if (
      this.machineData.machineType == "F10i" ||
      this.machineData.machineType == "F10i CG3"
    ) {
      if (
        this.toolingData.punchType == "EU19" ||
        this.toolingData.punchType == "FS19" ||
        this.toolingData.punchType == "TSM19"
      ) {
        if (
          this.toolingData.numberOfPunches == "32" ||
          this.toolingData.numberOfPunches == "30" ||
          this.toolingData.numberOfPunches == "24"
        ) {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "99") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (this.toolingData.punchType == "EU19FS") {
        if (
          this.toolingData.numberOfPunches == "30" ||
          this.toolingData.numberOfPunches == "24"
        ) {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "30") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (
        this.toolingData.punchType == "EU1“" ||
        this.toolingData.punchType == "TSM 1“"
      ) {
        if (this.toolingData.numberOfPunches == "20") {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "24") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (this.toolingData.punchType == "EU1“ 441") {
        if (this.toolingData.numberOfPunches == "20") {
          this.displayDie = "display: ";
          this.displaySeg = "display: none";
        } else if (this.toolingData.numberOfPunches == "21") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      } else if (this.toolingData.punchType == "FS12") {
        if (this.toolingData.numberOfPunches == "45") {
          this.displayDie = "display: none";
          this.displaySeg = "display: ";
        }
      }
    } else if (this.machineData.machineType == "FE35") {
      this.displayDie = "display: none";
      this.displaySeg = "display: ";
    } else if (
      this.machineData.machineType == "FE55" ||
      this.machineData.machineType == "FE55 CG3"
    ) {
      this.displayDie = "display: none";
      this.displaySeg = "display: ";
    } else if (
      this.machineData.machineType == "FE75" ||
      this.machineData.machineType == "FE75 CG3"
    ) {
      this.displayDie = "display: none";
      this.displaySeg = "display: ";
    } else if (this.machineData.machineType == "P1010") {
      this.displayDie = "display: ";
      this.displaySeg = "display: none";
    } else if (this.machineData.machineType == "P2020") {
      this.displayDie = "display: ";
      this.displaySeg = "display: none";
    } else if (this.machineData.machineType == "P3030") {
      this.displayDie = "display: ";
    } else if (this.machineData.machineType == "1090 WiP") {
      this.displayRadio = "display: ";
    } else if (this.machineData.machineType == "F20i") {
      this.displayRadio = "display: ";
    } else if (this.machineData.machineType == "F20i CG3") {
      this.displayRadio = "display: ";
    } else if (this.machineData.machineType == "2090 WiP") {
      this.displayRadio = "display: ";
    } else if (this.machineData.machineType == "F30i") {
      this.displayRadio = "display: ";
    } else if (this.machineData.machineType == "F30i CG3") {
      this.displayRadio = "display: ";
    } else if (this.machineData.machineType == "3090 WiP") {
      this.displayRadio = "display: ";
    } else if (this.machineData.machineType == "4090i") {
      this.displayRadio = "display: ";
    } else {
      this.displayDie = "display: none";
      this.displaySeg = "display: none";
    }
  }

  showSegments(): string {
    return "display: ";
  }
  showDies(): "display: none" | "display: " {
    if (
      +this.toolingData.numberOfPunches < 101 &&
      this.toolingData.numberOfPunches !== ""
    ) {
      return "display: ";
    } else {
      return "display: none";
    }
  }
  showDistributor(): "display: none" | "display: " {
    if (this.toolingData.numberOfPunches !== "") {
      return "display: ";
    } else {
      return "display: none";
    }
  }

  private client = new ConsultingDataAPI(awsconfig.API);
  async upload(): Promise<void> {
    await Storage.put(
      this.userData.userId + "/consultingData/toolingData.json",
      this.toolingData
    );
    const result = await Storage.list("");
    await this.client.saveConsutlingData(ConsultingStore.consultingData);
    console.log(result);
  }
}
