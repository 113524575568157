import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#002B46",
        secondary: "#00b3c1",
      },
    },
  },
});

export default vuetify;
