
import { Component, Prop, Vue } from "vue-property-decorator";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import OsdiTextField from "@/../shared-ui/components/Osdi/OsdiTextField.vue";
import OsdiDropDown from "@/components/OsdiDropDown.vue";
import OsdiFileInput from "@/components/OsdiFileInput.vue";
import { TabletData, FileData, UserData } from "@/models";
import ConsultingStore from "@/store/consultingModule";
import { Storage } from "aws-amplify";
import { ConsultingDataAPI } from "../api/consultingData";
import awsconfig from "@/aws-config";

@Component({
  components: {
    OsdiButton,
    OsdiTextField,
    OsdiDropDown,
    OsdiFileInput,
  },
})
export default class Tablet extends Vue {
  @Prop() private msg!: string;
  private tabletDrawing = {};
  private userData: UserData = ConsultingStore.userData;
  private fileData: FileData = ConsultingStore.fileData;
  private tabletData: TabletData = {
    height: "",
    weight: "",
    hardness: "",
    form: "",
    oblongNarrowSide: "",
    oblongLongSide: "",
    diameter: "",
    curvatureRadius: "",
  };
  mounted(): void {
    this.tabletData = JSON.parse(
      JSON.stringify(ConsultingStore.consultingData.tabletData)
    );
    console.log(this.tabletData);
  }
  updated(): void {
    ConsultingStore.SET_TABLETDATA(this.tabletData);
  }
  setEnable(): boolean {
    return (
      (this.tabletData.height.length > 0 &&
        this.tabletData.weight.length > 0 &&
        this.tabletData.hardness.length > 0) ||
      (this.tabletData.height.length > 0 &&
        this.tabletData.weight.length > 0 &&
        this.tabletData.hardness.length > 0 &&
        this.tabletData.form == "radio-round" &&
        this.tabletData.curvatureRadius.length > 0) ||
      (this.tabletData.height.length > 0 &&
        this.tabletData.weight.length > 0 &&
        this.tabletData.hardness.length > 0 &&
        this.tabletData.form == "radio-oblong" &&
        this.tabletData.curvatureRadius.length > 0 &&
        this.tabletData.oblongNarrowSide.length > 0 &&
        this.tabletData.oblongLongSide.length > 0) ||
      (this.tabletData.height.length > 0 &&
        this.tabletData.weight.length > 0 &&
        this.tabletData.hardness.length > 0 &&
        this.tabletData.form == "radio-custom" &&
        this.tabletData.curvatureRadius.length > 0 &&
        this.tabletData.oblongNarrowSide.length > 0 &&
        this.tabletData.oblongLongSide.length > 0 &&
        this.tabletData.diameter.length > 0)
    );
  }

  showSide(): "display:" | "display: none" {
    if (
      this.tabletData.form === "radio-oblong" ||
      this.tabletData.form === "radio-custom"
    ) {
      return "display:";
    } else {
      return "display: none";
    }
  }
  showRadius(): "display:" | "display: none" {
    if (
      this.tabletData.form === "radio-circle" ||
      this.tabletData.form === "radio-oblong" ||
      this.tabletData.form === "radio-custom"
    ) {
      return "display:";
    } else {
      return "display: none";
    }
  }
  showCustom(): "display:" | "display: none" {
    if (this.tabletData.form === "radio-custom") {
      return "display:";
    } else {
      return "display: none";
    }
  }
  attachTabletDrawingF(e: Event): void {
    this.tabletDrawing = e;
    this.fileData.files.push(this.tabletDrawing);
  }

  private client = new ConsultingDataAPI(awsconfig.API);
  async upload(): Promise<void> {
    await Storage.put(
      this.userData.userId + "/consultingData/tabletData.json",
      this.tabletData
    );
    const result = await Storage.list("");
    await this.client.saveConsutlingData(ConsultingStore.consultingData);
    console.log(result);
  }
}
