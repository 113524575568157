import { GraphQLAPIClass } from "@aws-amplify/api-graphql";

export interface GraphQlApiOptions {
  region?: string;
  aws_appsync_authenticationType?: string;
  aws_appsync_region?: string;
  aws_appsync_graphqlEndpoint?: string;
}

export class GraphQlApi {
  protected client: GraphQLAPIClass;
  constructor(private params: GraphQlApiOptions) {
    this.client = new GraphQLAPIClass(params);
    this.client.configure(params);
  }
}
