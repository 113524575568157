
import { Component, Prop, Vue } from "vue-property-decorator";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import OsdiTextField from "@/../shared-ui/components/Osdi/OsdiTextField.vue";
import OsdiDropDown from "@/components/OsdiDropDown.vue";
import OsdiFileInput from "@/components/OsdiFileInput.vue";
import { ParametersData, FileData, UserData } from "@/models";
import ConsultingStore from "@/store/consultingModule";
import { Storage } from "aws-amplify";
import { ConsultingDataAPI } from "../api/consultingData";
import awsconfig from "@/aws-config";

@Component({
  components: {
    OsdiButton,
    OsdiTextField,
    OsdiDropDown,
    OsdiFileInput,
  },
})
export default class Parameters extends Vue {
  @Prop() private msg!: string;
  private reports = [{}];
  private newFile = {};
  private parameterBatchDD1 = "";
  private parameterBatchDD2 = "";
  private parameterBatchDD3 = "";
  private parameterBatchDD4 = "";
  private parameterBatchDD5 = "";
  private counter = 1;
  private secondReport = "display:none";
  private thirdReport = "display:none";
  private fourthReport = "display:none";
  private fifthReport = "display:none";
  private buttonDisable = false;
  private labelP18 = false;
  private labelP19 = false;
  private labelP20 = false;
  private labelP21 = false;
  private fileDis = false;

  private ratingBatch = ["Good Batch", "Average Batch", "Bad Batch"];
  private fileData: FileData = ConsultingStore.fileData;
  private userData: UserData = ConsultingStore.userData;
  private parametersData: ParametersData = JSON.parse(
    JSON.stringify(ConsultingStore.consultingData.parametersData)
  );

  disable(): boolean {
    this.fileDis = !this.fileDis;
    return this.fileDis;
  }
  mounted(): void {
    this.parametersData = JSON.parse(
      JSON.stringify(ConsultingStore.consultingData.parametersData)
    );
  }

  updated(): void {
    ConsultingStore.setParametersData(this.parametersData);
  }

  counterUp(): void {
    this.counter++;
  }
  reportRating(newValue: string, index: number): void {
    if (index == 0) {
      this.parameterBatchDD1 = newValue;
    } else if (index == 1) {
      this.parameterBatchDD2 = newValue;
    } else if (index == 2) {
      this.parameterBatchDD3 = newValue;
    } else if (index == 3) {
      this.parameterBatchDD4 = newValue;
    } else if (index == 4) {
      this.parameterBatchDD5 = newValue;
    }
  }

  fileInput(): "background-color: #BEBEBE" | undefined {
    if (
      this.parametersData.fillingDeviceSpeed ||
      this.parametersData.tabletsPerHours ||
      this.parametersData.mainCompressionForces ||
      this.parametersData.fillingDepth ||
      this.parametersData.preCompressionForces ||
      this.parametersData.mainCompressionStationCylindricalHeight ||
      this.parametersData.preCompressionStationCylindricalHeight ||
      this.parametersData.mainCompressionStationPenetrationDepth ||
      this.parametersData.mainCompressionStationPenetrationDepth2 ||
      this.parametersData.fillCam
    ) {
      return "background-color: #BEBEBE";
    }
  }
  fileInput2(): true | undefined {
    if (
      this.parametersData.fillingDeviceSpeed ||
      this.parametersData.tabletsPerHours ||
      this.parametersData.mainCompressionForces ||
      this.parametersData.fillingDepth ||
      this.parametersData.preCompressionForces ||
      this.parametersData.mainCompressionStationCylindricalHeight ||
      this.parametersData.preCompressionStationCylindricalHeight ||
      this.parametersData.mainCompressionStationPenetrationDepth ||
      this.parametersData.mainCompressionStationPenetrationDepth2 ||
      this.parametersData.fillCam
    ) {
      return true;
    }
  }
  setEnable(): string | false {
    return (
      this.parametersData.fillingDeviceSpeed.length > 0 &&
      this.parametersData.tabletsPerHours.length > 0 &&
      this.parametersData.mainCompressionForces.length > 0 &&
      this.parametersData.fillingDepth.length > 0 &&
      this.parametersData.preCompressionForces.length > 0 &&
      this.parametersData.mainCompressionStationCylindricalHeight.length > 0 &&
      this.parametersData.preCompressionStationCylindricalHeight.length > 0 &&
      this.parametersData.mainCompressionStationPenetrationDepth.length > 0 &&
      this.parametersData.mainCompressionStationPenetrationDepth2.length > 0 &&
      this.parametersData.fillCam.length > 0 &&
      this.parametersData.mainCompressionSRel
    );
  }

  showBatch(): void {
    if (this.counter == 1) {
      this.secondReport = "";
      this.counter = this.counter + 1;
    } else if (this.counter == 2) {
      this.secondReport = "";
      this.thirdReport = "";
      this.counter = this.counter + 1;
    } else if (this.counter == 3) {
      this.secondReport = "";
      this.thirdReport = "";
      this.fourthReport = "";
      this.counter = this.counter + 1;
    } else if (this.counter == 4) {
      this.secondReport = "";
      this.thirdReport = "";
      this.fourthReport = "";
      this.fifthReport = "";
      this.counter = this.counter + 1;
      this.buttonDisable = true;
    }
  }
  newFileUp(e: Event): void {
    this.newFile = e;
    if (this.newFile != null) {
      this.fileData.files.push(this.newFile);
      console.log(this.fileData.files);
    }
  }

  private client = new ConsultingDataAPI(awsconfig.API);
  async upload(): Promise<void> {
    await Storage.put(
      this.userData.userId + "/consultingData/parametersData.json",
      this.parametersData
    );
    const result = await Storage.list("");
    await this.client.saveConsutlingData(ConsultingStore.consultingData);
    console.log(result);
  }
}
