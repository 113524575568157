
import DefaultLayoutSidebarLogo from "@/../shared-ui/components/Layout/Sidebar/DefaultLayoutSidebarLogo.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "DefaultDrawer",
  components: {
    DefaultLayoutSidebarLogo,
  },
})
export default class TopBar extends Vue {
  other = true;

  get userLoggedIn(): boolean {
    // to be implemented use user store
    return false;
  }
  get showCancel(): boolean {
    return this.$route.meta?.cancelButtonBool !== false;
  }
  get topBarName(): string {
    return this.$route.meta?.topBarName || "Request consulting";
  }
  get sidebarLogo(): any {
    return {
      imgVariant: require("@/assets/img/layout/sidebar-logo.svg"),
      textVariant: require("@/assets/img/layout/sidebar-logo-text.svg"),
    };
  }
}
