import { changeLanguage } from "../utils/utils";
import sidebarLinks from "./sidebarLinks";
import { IVueI18n } from "vue-i18n/types";

export default (i18n: IVueI18n, router: any, route: any) => ({
  top() {
    return [
      {
        title: i18n.t("sidebar.top.consulting").toString(),
        icon: "mdi-speedometer",
        to: "/",
        children: [],
      },
      {
        title: i18n.t("sidebar.top.apps").toString(),
        icon: "mdi-apps",
        to: "",
        children: [
          {
            title: i18n.t("sidebar.top.dynamicData").toString(),
            icon: "mdi-poll",
            to: "/dynamic-data",
          },
        ],
      },
    ];
  },

  bottom() {
    const links = sidebarLinks(i18n.locale);
    console.log(links.imprint);

    return [
      {
        title: i18n.t("sidebar.top.language").toString(),
        icon: "mdi-translate",
        to: "",
        children: [
          {
            title: "English",
            icon: "",
            to: "",
            onClick: () => changeLanguage("en", i18n, router, route),
          },
          {
            title: "Deutsch",
            icon: "",
            to: "",
            onClick: () => changeLanguage("de", i18n, router, route),
          },
        ],
      },
      {
        title: i18n.t("sidebar.bottom.imprint").toString(),
        icon: "mdi-note-text-outline",
        href: links.imprint,
      },
      {
        title: i18n.t("sidebar.bottom.informationOnDataProtection").toString(),
        icon: "mdi-shield-check-outline",
        href: links.dataProtection,
      },
      {
        title: i18n.t("sidebar.bottom.termsConditions").toString(),
        icon: "mdi-book-outline",
        href: links.legalNotice,
      },
    ];
  },
});
