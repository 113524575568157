import { render, staticRenderFns } from "./Tooling.vue?vue&type=template&id=39fb9caa&scoped=true&"
import script from "./Tooling.vue?vue&type=script&lang=ts&"
export * from "./Tooling.vue?vue&type=script&lang=ts&"
import style0 from "./Tooling.vue?vue&type=style&index=0&id=39fb9caa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39fb9caa",
  null
  
)

export default component.exports