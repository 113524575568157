interface Links {
  imprint: string;
  dataProtection: string;
  legalNotice: string;
}

const menuLinks = {
  en: {
    imprint: "https://www.fette-compacting.com/en/legal-note",
    dataProtection:
      "https://accounts.osdi-solutions.com/privacy-policy?lang=en",
    legalNotice: "https://www.fette-compacting.com/en/gtc",
  },
  de: {
    imprint: "https://www.fette-compacting.com/de/impressum",
    dataProtection:
      "https://accounts.osdi-solutions.com/privacy-policy?lang=de",
    legalNotice: "https://www.fette-compacting.com/de/agb",
  },
};

export default (locale: string): Links => {
  if (locale === "en") {
    return menuLinks.en;
  }
  return menuLinks.de;
};
