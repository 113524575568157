
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ConsultingHomeLayout from "./ConsultingHomeLayout.vue";
import {
  SidebarItem,
  SidebarLogoProps,
} from "@/../shared-ui/interfaces/sidebarProps";
import sidebar from "../data/sidebarItems";
import UserModel from "@/../shared-ui/models/UserModel";

@Component({
  name: "Layout",
  components: { ConsultingHomeLayout },
})
export default class Layout extends Vue {
  @Prop({ default: false }) readonly noGuttersHeader!: boolean;
  @Prop({ default: false }) readonly loggedIn!: boolean;
  private clipped = false;
  private sidebarTopItems: SidebarItem[] = [];
  private sidebarBottomItems: SidebarItem[] = [];

  private sidebarLogoProps: SidebarLogoProps = {
    imgVariant: require("@/assets/img/layout/sidebar-logo.svg"),
    textVariant: require("@/assets/img/layout/sidebar-logo-text.svg"),
  };

  get user(): UserModel {
    return this.$store.state.user.user;
  }

  get currentLocale(): string {
    return this.$i18n.locale;
  }

  @Watch("currentLocale")
  setSidebarItems(): void {
    this.sidebarTopItems = sidebar(this.$i18n, this.$router, this.$route).top();
    this.sidebarBottomItems = [
      ...sidebar(this.$i18n, this.$router, this.$route).bottom(),
      {
        title: this.user.username,
        icon: "mdi-account-circle-outline",
        to: "/components/profile",
        children: [],
      },
      {
        title: this.$i18n.t("sidebar.bottom.logout").toString(),
        icon: "mdi-logout",
        to: "/auth/login",
        children: [],
      },
    ];
  }

  setDefaultLocale(): void {
    let locale: string | null = this.$route.query.lang
      ? this.$route.query.lang.toString()
      : null;

    if (!locale) {
      const l: string | null = localStorage.getItem("locale");
      locale = l || "en";
    }

    if (locale === "en" || locale === "de") {
      this.$i18n.locale = locale;
    } else this.$i18n.locale = "en";
  }

  created(): void {
    this.setDefaultLocale();
    this.setSidebarItems();
  }
}
