import {
  ConsultingData,
  MachineData,
  SetupData,
  FillingData,
  ToolingData,
  ParametersData,
  ProductData,
  TabletData,
  AnalysisData,
  FileData,
  UserData,
} from "@/models";
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from ".";

@Module({ dynamic: true, namespaced: true, store, name: "consultingModule" })
class ConsultingStore extends VuexModule {
  public consultingData: ConsultingData = {
    userId: "",
    machineData: {
      conYear: "",
      serialNumber: "",
      opcua: "",
      machineType: "",
      visualVersion: "",
      windowsVersion: "",
      macoVersion: "",
    },
    setUpData: {
      layout: "",
      sorting1: "",
      sorting2: "",
      materialPlough: "",
      materialScraper: "",
      tabletTester: "",
      metalDetector: "",
      dustExtraction: "",
      productLoading: "",
    },
    fillingData: {
      fillingType: "",
      dosingWheel: "",
      fillingWheel: "",
      distributionWheel: "",
      direction: "",
      intermediatePlate: "",
      basePlate: "",
      sealing: "",
      distance: "",
      discribeFillingDevice: "",
      fillingConeSealing: "",
      fillingDeviceModel: "",
      fillingTypeModel: "",
      material: "",
    },
    toolingData: {
      numberOfPunches: "",
      punchType: "",
      punchCoating: "",
      tabletOrientation: "",
      dies: "",
      diesTaper: "",
      segmentsID: "",
      segmentsTaper: "",
      toolingDistributorSegment: "",
      toolingDistributorDie: "",
    },
    parametersData: {
      tabletsPerHours: "",
      fillingDeviceSpeed: "",
      mainCompressionForces: "",
      fillingDepth: "",
      preCompressionForces: "",
      mainCompressionStationCylindricalHeight: "",
      preCompressionStationCylindricalHeight: "",
      mainCompressionStationPenetrationDepth: "",
      mainCompressionStationPenetrationDepth2: "",
      fillCam: "",
      mainCompressionSRel: "",
      parameterBatchDD1: "",
      parameterBatchDD2: "",
      parameterBatchDD3: "",
      parameterBatchDD4: "",
      parameterBatchDD5: "",
    },
    productData: {
      activeIngredient: "",
      productName: "",
      apiContent: "",
      meltingPoint: "",
      material: "",
      practicalSizeDistribution: "",
      residualMoisture: "",
      bulkDensity: "",
      tappedDensity: "",
      specialties: "",
    },
    tabletData: {
      height: "",
      weight: "",
      hardness: "",
      form: "",
      oblongNarrowSide: "",
      oblongLongSide: "",
      diameter: "",
      curvatureRadius: "",
    },
    analysisData: {
      topic: "",
      majorIssues: "",
      impactProduction: "",
      typicallyOccur: "",
      typicallyOccurOthers: "",
      consultingMode: "",
      relatePerformance: "",
      name: "",
      surname: "",
      company: "",
      emailAddress: "",
      phoneNumber: "",
    },
  };
  public fileData: FileData = {
    files: [{}],
  };
  public userData: UserData = {
    userId: "",
  };

  @Mutation
  SET_MACHINEDATA(machineData: MachineData) {
    this.consultingData.machineData = machineData;
  }
  @Mutation
  SET_SETUPDATA(setupData: SetupData) {
    this.consultingData.setUpData = setupData;
  }
  @Mutation
  SET_FILLINGDATA(fillingData: FillingData) {
    this.consultingData.fillingData = fillingData;
  }
  @Mutation
  SET_TOOLINGDATA(toolingData: ToolingData) {
    this.consultingData.toolingData = toolingData;
  }
  @Mutation
  SET_PARAMETERSDATA(parametersData: ParametersData) {
    this.consultingData.parametersData = parametersData;
  }
  @Mutation
  SET_PRODUCTDATA(productData: ProductData) {
    this.consultingData.productData = productData;
  }
  @Mutation
  SET_TABLETDATA(tabletData: TabletData) {
    this.consultingData.tabletData = tabletData;
  }
  @Mutation
  SET_ANALYSISDATA(analysisData: AnalysisData) {
    this.consultingData.analysisData = analysisData;
  }
  @Mutation
  SET_FILEDATA(fileData: FileData) {
    this.fileData = fileData;
  }
  @Mutation
  SET_USERDATA(userData: UserData) {
    this.userData = userData;
  }

  // action 'incr' commits mutation 'increment' when done with return value as payload
  @Action({ commit: "SET_MACHINEDATA" })
  setMachineData(machineData: MachineData) {
    return machineData;
  }
  @Action({ commit: "SET_SETUPDATA" })
  setSetupData(setupData: SetupData) {
    return setupData;
  }
  @Action({ commit: "SET_FILLINGDATA" })
  setFillingData(fillingData: FillingData) {
    return fillingData;
  }

  @Action({ commit: "SET_TOOLINGDATA" })
  setToolingData(toolingData: ToolingData) {
    return toolingData;
  }
  @Action({ commit: "SET_PARAMETERSDATA" })
  setParametersData(parametersData: ParametersData) {
    return parametersData;
  }
  @Action({ commit: "SET_PRODUCTDATA" })
  setProductData(productData: ProductData) {
    return productData;
  }
  @Action({ commit: "SET_TABLETDATA" })
  setTabletData(tabletData: TabletData) {
    return tabletData;
  }
  @Action({ commit: "SET_ANALYSISDATA" })
  setAnalysisData(analysisData: AnalysisData) {
    return analysisData;
  }
  @Action({ commit: "SET_FILEDATA" })
  setFileData(fileData: FileData) {
    return fileData;
  }
  @Action({ commit: "SET_USERDATA" })
  setUserData(userData: UserData) {
    return userData;
  }
}
export default getModule(ConsultingStore);
