import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Index from "../views/Index.vue";
import Machine from "../views/Machine.vue";
import Setup from "../views/Setup.vue";
import Filling from "../views/Filling.vue";
import Tooling from "../views/Tooling.vue";
import Parameters from "../views/Parameters.vue";
import Product from "../views/Product.vue";
import Tablet from "../views/Tablet.vue";
import Analysis from "../views/Analysis.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Start",
    component: Index,
    meta: {
      count: 0,
      sidebarBool: false,
      cancelButtonBool: false,
      topBarName: "Consulting",
    },
  },
  {
    path: "/machine",
    name: "machine",
    component: Machine,
    meta: {
      count: 0,
    },
  },
  {
    path: "/setup",
    name: "Setup",
    component: Setup,
    meta: { count: 2 },
  },
  {
    path: "/filling",
    name: "Filling",
    component: Filling,
    meta: { count: 4 },
  },
  {
    path: "/tooling",
    name: "Tooling",
    component: Tooling,
    meta: { count: 6 },
  },
  {
    path: "/parameters",
    name: "Parameters",
    component: Parameters,
    meta: { count: 8 },
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
    meta: { count: 10 },
  },
  {
    path: "/tablet",
    name: "Tablet",
    component: Tablet,
    meta: { count: 12 },
  },
  {
    path: "/Analysis",
    name: "Analysis",
    component: Analysis,
    meta: { count: 14 },
  },
  {
    path: "/about",
    name: "About",
    meta: { count: 0 },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
