
import { Component, Prop, Vue } from "vue-property-decorator";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import OsdiTextField from "@/../shared-ui/components/Osdi/OsdiTextField.vue";
import OsdiDropDown from "@/components/OsdiDropDown.vue";
import { FillingData, UserData } from "@/models";
import ConsultingStore from "@/store/consultingModule";
import { fill } from "lodash";
import { Storage } from "aws-amplify";
import { ConsultingDataAPI } from "../api/consultingData";
import awsconfig from "@/aws-config";

@Component({
  components: {
    OsdiButton,
    OsdiTextField,
    OsdiDropDown,
  },
})
export default class Filling extends Vue {
  @Prop() private msg!: string;
  private labelSealingSegSiCone = false;
  private labelMaterial = false;
  private labelFillingWheel = false;
  private labelDosingWheel = false;
  private labelIntermediatePlate = false;
  private labelBasePlate = false;
  private labelSealingSegSiChamber = false;
  private labelDistributionWheel = false;
  private wheelAttr = ["Angled", "Round"];
  private intermediatePlateTypes = ["Standard", "Big Openings"];
  private basePlateTypes = [
    "with slide in Segment",
    "without slide in Segment",
  ];
  private sealingSize = ["11", "16", "20", "25"];
  private material = ["Aluminium", "Stainless Steel"];
  private userData: UserData = ConsultingStore.userData;
  private fillingData: FillingData = JSON.parse(
    JSON.stringify(ConsultingStore.consultingData.fillingData)
  );
  private fillingDevice = ["Filling Cone", "Three Chamber", "Others"];

  mounted() {
    this.fillingData = JSON.parse(
      JSON.stringify(ConsultingStore.consultingData.fillingData)
    );
  }
  updated() {
    ConsultingStore.SET_FILLINGDATA(this.fillingData);
  }

  setEnable() {
    return (
      (this.fillingData.material &&
        this.fillingData.fillingType.length > 0 &&
        this.fillingData.dosingWheel.length > 0 &&
        this.fillingData.fillingWheel.length > 0 &&
        this.fillingData.distributionWheel.length > 0 &&
        this.fillingData.direction.length > 0 &&
        this.fillingData.intermediatePlate.length > 0 &&
        this.fillingData.basePlate.length > 0 &&
        this.fillingData.sealing.length > 0 &&
        this.fillingData.distance.length > 0) ||
      this.fillingData.discribeFillingDevice.length > 0 ||
      this.fillingData.fillingConeSealing.length > 0
    );
  }

  displayOther() {
    if (this.fillingData.fillingDeviceModel === "Others") {
      return "display: ";
    } else {
      return "display: none";
    }
  }
  displayThreeChambers() {
    if (this.fillingData.fillingDeviceModel === "Three Chamber") {
      return "display: ";
    } else {
      return "display: none";
    }
  }
  displayFillingCone() {
    if (this.fillingData.fillingDeviceModel === "Filling Cone") {
      return "display: ";
    } else {
      return "display: none";
    }
  }
  private client = new ConsultingDataAPI(awsconfig.API);
  async upload() {
    await Storage.put(
      this.userData.userId + "/consultingData/fillingData.json",
      this.fillingData
    );
    const result = await Storage.list("");
    await this.client.saveConsutlingData(ConsultingStore.consultingData);
    console.log(result);
  }
}
