
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  SidebarItem,
  SidebarLogoProps,
} from "@/../shared-ui/interfaces/sidebarProps";
import { LanguageSwitcherProps } from "@/../shared-ui/interfaces/language-switcherProps";
import DefaultLayoutSidebar from "@/../shared-ui/components/Layout/Sidebar/DefaultLayoutSidebar.vue";

@Component({
  name: "DefaultLayout",
  components: {
    DefaultLayoutSidebar,
  },
})
export default class ConsultingHomeLayout extends Vue {
  @Prop() readonly loggedIn?: boolean;
  @Prop() readonly clipped?: boolean;
  @Prop() readonly sidebarTopItems?: SidebarItem[];
  @Prop() readonly sidebarBottomItems?: SidebarItem[];
  @Prop() readonly sidebarLanguageSwitcherProps?: LanguageSwitcherProps;
  @Prop() readonly sidebarLogoProps?: SidebarLogoProps;

  get loggedInUser(): boolean {
    return this.loggedIn ? true : false;
  }
}
