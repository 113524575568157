
import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class SideBar extends Vue {
  pages: string[] = [
    "Machine",
    "",
    "Setup",
    "",
    "Filling",
    "",
    "Tooling",
    "",
    "Parameters",
    "",
    "Product",
    "",
    "Tablet",
    "",
    "Analysis",
  ];

  get showSideNavigation(): boolean {
    return this.$route.meta?.sidebarBool !== false;
  }
}
