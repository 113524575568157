
import { Component, Prop, Vue } from "vue-property-decorator";
import { SidebarLogoProps } from "../../../interfaces/sidebarProps";

@Component({
  name: "DefaultLayoutSidebarLogo",
})
export default class DefaultLayoutSidebarLogo extends Vue {
  @Prop({ required: true }) readonly miniVariantStatus?: boolean;
  @Prop() readonly sidebarLogoImgVariant?: string;
  @Prop() readonly sidebarLogoTextVariant?: string;

  get sidebarLogo(): SidebarLogoProps {
    return {
      imgVariant: this.sidebarLogoImgVariant,
      textVariant: this.sidebarLogoTextVariant,
    };
  }
}
