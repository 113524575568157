
import { Component, Prop, Vue } from "vue-property-decorator";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import OsdiTextField from "@/../shared-ui/components/Osdi/OsdiTextField.vue";
import OsdiDropDown from "@/components/OsdiDropDown.vue";
import { AnalysisData, FileData, UserData } from "@/models";
import ConsultingStore from "@/store/consultingModule";
import { Storage } from "aws-amplify";
import { ConsultingDataAPI } from "../api/consultingData";
import awsconfig from "@/aws-config";
import { result } from "lodash";

@Component({
  components: {
    OsdiButton,
    OsdiTextField,
    OsdiDropDown,
  },
})
export default class Analysis extends Vue {
  @Prop() private msg!: string;
  private typicalOccur = [
    "Start of Batch",
    "Middel of Batch",
    "End Of Batch",
    "Others",
  ];
  private userData: UserData = ConsultingStore.userData;
  private analysisData: AnalysisData = JSON.parse(
    JSON.stringify(ConsultingStore.consultingData.analysisData)
  );
  private fileData: FileData = ConsultingStore.fileData;

  mounted() {
    this.analysisData = JSON.parse(
      JSON.stringify(ConsultingStore.consultingData.analysisData)
    );
  }
  updated() {
    ConsultingStore.SET_ANALYSISDATA(this.analysisData);
  }
  showOthers() {
    if (this.analysisData.typicallyOccur === "Others") {
      return "display:";
    } else {
      return "display:none";
    }
  }
  setEnable() {
    return (
      this.analysisData.topic.length > 0 &&
      this.analysisData.majorIssues.length > 0 &&
      this.analysisData.typicallyOccur.length > 0 &&
      this.analysisData.impactProduction.length > 0 &&
      this.analysisData.consultingMode.length > 0 &&
      this.analysisData.name.length > 0 &&
      this.analysisData.surname.length > 0 &&
      this.analysisData.company.length > 0 &&
      this.analysisData.emailAddress.length > 0 &&
      this.analysisData.phoneNumber.length > 0
    );
  }
  private client = new ConsultingDataAPI(awsconfig.API);
  async upload() {
    await Storage.put(
      this.userData.userId + "/consultingData/analysisData.json",
      this.analysisData
    );
    const result = await Storage.list("");
    console.log(result);
    for (let i = 1; i < this.fileData.files.length; i++) {
      await Storage.put(
        this.userData.userId +
          "/consultingData/consultingDataFile" +
          i +
          ".jpg",
        this.fileData.files[i]
      );
    }
    await this.client.saveConsutlingData(ConsultingStore.consultingData);
    await this.client.sendEmailOnSubmit(this.userData.userId);
  }
}
